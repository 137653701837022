/* eslint-disable */
import { FC, useReducer, useState } from 'react';
import { formQuestReducer, FormQuestContext } from './../../reducer/reducer';
import { formQuestInitStore } from './../../store/formQuestInitStore';

import Header from './../layout/header/Header';
import BannerThanks from './../layout/banner/bannerThanks/BannerThanks';
import BannerInner from './../layout/banner/bannerInner/BannerInner';
import FormQuest from './../form/FormQuest';
import Tabs from '../layout/tabs/Tabs';
import BannerAdv from './../layout/banner/bannerAdv/BannerAdv';
import Roadmap from './../layout/roadmap/Roadmap';
import Advantage from './../layout/advantage/Advantage';
import Features from './../layout/features/Features';
import Footer from './../layout/footer/Footer';
import PayMonthly from './../layout/payMonthly/PayMonthly';
import CookieBanner from './CookieBanner';

const App: FC = () => {
    const [showCookieBanner, setShowCookieBanner] = useState(
        localStorage.getItem('acceptCookie') === null,
    );
    const [state, dispatch] = useReducer(formQuestReducer, formQuestInitStore);
    const handleAccept = () => {
        setShowCookieBanner(false);
        localStorage.setItem('acceptCookie', 'true');
    };

    return (
        <>
            <Header />
            {/* <BannerThanks /> */}
            <FormQuestContext.Provider value={{ dispatch, state }}>
                <FormQuest />
            </FormQuestContext.Provider>
            <Tabs />
            <BannerAdv title="Получите кредит наличными по паспорту" />
            <Roadmap />
            <PayMonthly />
            <Advantage />
            <Features />
            <BannerAdv title="Оформить кредит наличными прямо сейчас" />
            <Footer />
            {showCookieBanner && <CookieBanner onAccept={handleAccept} />}
        </>
    );
};

export default App;
