import { useContext, useEffect, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import { setUnitСode, setIssuedBy } from '../../../../actions/formQuest';
import { useDadataFmsUnit } from '../../../../hooks/dadataApiHooks';
import { FormQuestContext } from '../../../../reducer/reducer';
import useStep4 from '../../../../services/formQuestServise/Step4Servise';
import { SuggestInput } from '../../suggestInput/SuggestInput';

/** параметры дадаты */
const params = { count: 5 };

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <HighlightWords
                searchWords={[params.query]}
                textToHighlight={`${suggestion.data.code} ${suggestion.data.name}`}
            />
        </div>
    );
};

export const UnitCodeSuggest = () => {
    const [suggestions, setSuggestions] = useState([]);

    const { data, trigger } = useDadataFmsUnit(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const { unitСode, issuedBy } = state;

    const { maskUnitСode, validationUnitСode } = useStep4();

    const handleChange = (e: any, { newValue }: any) => {
        setUnitСode(dispatch, unitСode, maskUnitСode(newValue), false);
        setIssuedBy(dispatch, issuedBy, '', false);
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const unitСodeValue = data.suggestion.data.code;
        const isValid = validationUnitСode(unitСode.errorMessages, unitСodeValue);

        setUnitСode(dispatch, unitСode, maskUnitСode(unitСodeValue), isValid);

        if (isValid) {
            setIssuedBy(dispatch, issuedBy, data.suggestion.data.name, true);
        }
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: '___-___',
        value: unitСode.value,
        onChange: handleChange,
        onBlur: () => {
            const isValid = validationUnitСode(
                unitСode.errorMessages,
                unitСode.value,
                issuedBy.isValid === true,
            );

            setUnitСode(dispatch, unitСode, maskUnitСode(unitСode.value), isValid);
        },
    };

    return (
        <SuggestInput
            label="Код подразделения"
            inputProps={inputProps}
            isValid={unitСode.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
