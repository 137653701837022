import { useContext, useEffect, useRef, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import { useDadataAddress } from '../../../../hooks/dadataApiHooks';
import { SuggestInput } from '../../suggestInput/SuggestInput';

import { setWorkAddress, setWorkAddressDependentFields } from './../../../../actions/formQuest';
import { FormQuestContext } from './../../../../reducer/reducer';
import useStep3 from './../../../../services/formQuestServise/Step3Servise';

/** параметры дадаты */
const locations = [{ country_iso_code: 'RU' }];
const params = {
    count: 5,
    locations,
    from_bound: { value: 'region' },
    to_bound: { value: 'flat' },
};

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <HighlightWords searchWords={[params.query]} textToHighlight={suggestion.value} />
        </div>
    );
};

export const WorkAddressSuggest = () => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const { data, trigger } = useDadataAddress(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const { workAddress } = state;

    const { validateWorkAddress } = useStep3();

    const handleChange = (e: any, { newValue }: any) => {
        setWorkAddress(dispatch, workAddress, newValue, false, false);
        setWorkAddressDependentFields(dispatch, {
            workCity: '',
            workStreet: '',
            workHouse: '',
            workRegionKladr: '',
            workCityKladr: '',
            workStreetKladr: '',
            workHouseKladr: '',
        });
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const workAddressValue = data.suggestion.value;
        const isValid = validateWorkAddress(
            workAddress.errorMessages,
            {
                value: workAddressValue,
                city: data.suggestion.data.city || data.suggestion.data.settlement,
                street: data.suggestion.data.street,
                house: data.suggestion.data.house,
            },
            true,
        );

        setWorkAddress(
            dispatch,
            workAddress,
            isValid !== true ? `${workAddressValue} ` : workAddressValue,
            isValid,
            true,
        );

        if (isValid === true) {
            setWorkAddressDependentFields(dispatch, {
                workCity: data.suggestion.data.city || data.suggestion.data.settlement || null,
                workStreet: data.suggestion.data.street || 'Нет',
                workHouse: data.suggestion.data.house || '',
                workRegionKladr: data.suggestion.data.region_kladr_id || '',
                workCityKladr: data.suggestion.data.city_kladr_id || '',
                workStreetKladr: data.suggestion.data.street_kladr_id || '',
                workHouseKladr: data.suggestion.data.house_kladr_id || '',
            });
        }
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: 'Фактический адрес работы',
        value: workAddress.value,
        onChange: handleChange,
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;

                setTimeout(() => {
                    r.setSelectionRange(workAddress.value.length, workAddress.value.length);
                }, 10);
            }
        },
        onBlur: () => {
            setWorkAddress(
                dispatch,
                workAddress,
                workAddress.value,
                workAddress.isDadata
                    ? workAddress.isValid
                    : workAddress.value === ''
                    ? workAddress.isValid
                    : workAddress.errorMessages.default,
                false,
            );

            setFocused(false);
        },
        ref: inputRef,
    };

    return (
        <SuggestInput
            label="Фактический адрес работы"
            inputProps={inputProps}
            isValid={workAddress.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
