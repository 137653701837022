import { FC, useContext, useEffect, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import { setEmail } from '../../../../actions/formQuest';
import { useDadataEmail } from '../../../../hooks/dadataApiHooks';
import { FormQuestContext } from '../../../../reducer/reducer';
import useStep1 from '../../../../services/formQuestServise/Step1Servise';
import { getBadgeContent } from '../../../../utils';
import { Badge } from '../../badge/Badge';
import { SuggestInput } from '../../suggestInput/SuggestInput';

interface Props {
    nextFieldRef: any;
    inputRef: any;
}

/** параметры дадаты */
const params = { count: 5 };

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <HighlightWords searchWords={[params.query]} textToHighlight={suggestion.value} />
        </div>
    );
};

export const EmailSuggest: FC<Props> = ({ nextFieldRef, inputRef }) => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const { data, trigger } = useDadataEmail(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const { email } = state;

    const { maskEmail, validationEmail } = useStep1();

    const handleChange = (e: any, { newValue }: any) => {
        setEmail(dispatch, email, maskEmail(newValue), false);
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const emailValue = data.suggestion.value;
        const isValid = validationEmail(email.errorMessages, emailValue);

        setEmail(dispatch, email, maskEmail(emailValue), isValid);

        if (isValid === true && nextFieldRef.current) {
            setTimeout(() => {
                nextFieldRef.current.focus();
            });
        }
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: 'ivan.ivanov@mail.com',
        value: email.value,
        onChange: handleChange,
        onKeyDown: (e: any) => {
            if (e.key === 'Enter') {
                const isValid = validationEmail(email.errorMessages, email.value);

                setEmail(dispatch, email, maskEmail(email.value), isValid);

                if (isValid === true && nextFieldRef.current) {
                    nextFieldRef.current.focus();
                }
            }
        },
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;

                setTimeout(() => {
                    r.setSelectionRange(email.value.length, email.value.length);
                }, 10);
            }
        },
        onBlur: () => {
            setFocused(false);
            const isValid = validationEmail(email.errorMessages, email.value);

            setEmail(dispatch, email, maskEmail(email.value), isValid);
        },
        ref: inputRef,
    };

    const badgeContent = getBadgeContent(email.points);

    const inputBadge = email.isValid ? (
        email.isValid === true ? null : (
            <Badge badgeContent={badgeContent} />
        )
    ) : focused ? (
        <Badge badgeContent={badgeContent} />
    ) : null;

    return (
        <SuggestInput
            label="Email"
            inputBadge={inputBadge}
            inputProps={inputProps}
            isValid={email.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
