import { FC, useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

import { TERMS } from '../../layout/policy/Terms';

import './style.css';

import logo from './../../../assets/imgs/logo/logo.svg';

const modalStyles = { content: { zIndex: 11 }, overlay: { zIndex: 11 } };

const Footer: FC = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClick = (e: any) => {
        e.preventDefault();
        setShowModal(true);
    };

    return (
        <>
            <footer className="footer">
                <div className="footer__content">
                    <Link to="/" className="footer__logo">
                        <img src={logo} alt="Лого" />
                    </Link>
                    <div className="footer__data row">
                        <a className="footer__title footer__title-link" onClick={handleClick}>
                            Политика конфиденциальности
                        </a>
                        <div className="footer__organization">
                            <span>
                                © {new Date().getFullYear()} Решбанк. ООО «Кредитмарт» г. Москва,
                                ул. Павловская д.6
                            </span>
                            <span>ИНН 7725321452 / КПП 772501001. ОГРН 1167746596896</span>
                        </div>
                    </div>
                    <p className="footer__text">Условия получения:</p>
                    <p className="footer__text">
                        Кредит предоставляется в размере 5 000 руб. – 10 000 000 руб. годовой
                        процент за пользование кредитом (с учетом требований ФЗ №353-ФЗ) – 7.5%-29%.
                        Окончательные условия получения будут предложены в индивидуальном порядке.
                    </p>
                    <p className="footer__text">
                        Размер неустойки составляет 0,10% от суммы просроченной задолженности за
                        каждый день нарушения обязательств, но не более 10% от суммы.
                    </p>
                    <p className="footer__text">
                        Пример расчета займа: Вы берете 30 000 рублей на 3 месяца с процентной
                        ставкой 4,5% в месяц переплата за весь срок составит 4 050 рублей. Итого Вы
                        возвращаете 34 050 рублей. Ставка 0% действует для новых клиентов, при
                        условии полного погашения без просрочки.
                    </p>
                    <p className="footer__text">
                        Срок кредита от 65 дней до 5 лет. Возможно как продление кредита, так и его
                        досрочного погашение. При досрочном погашении комиссия за использование
                        займа будет пересчитана пропорционально сроку пользования деньгами.
                    </p>
                    <p className="footer__text">Последствия невыплаты заёмных средств:</p>
                    <p className="footer__text">
                        В случае невозвращения общей суммы займа, кредитор вынуждено начислит штраф
                        за просрочку займа. Большинство кредиторов идут на уступки и дают
                        дополнительные 3 рабочих дня для оплаты. Они предусмотрены специально для
                        Вас на случай, к примеру, если банковский перевод занял больше времени, чем
                        обычно, или Вы просто поздно отправили сумму. Однако, в случае неполучения
                        от Вас какой-либо реакции в течение продолжительного времени, будет начислен
                        штраф за просрочку срока погашения займа размером в среднем 0,10% от
                        первоначальной суммы займа, данные о Вас могут быть переданы в реестр
                        должников, а задолженность коллекторскому агентству для взыскания долга.
                    </p>
                    <p className="footer__text">
                        О всех приближающихся сроках платежа, кредитор своевременно информируем Вас
                        по СМС и электронной рассылкой писем. Рекомендуем Вам вносить платеж в день
                        получения данных напоминаний. Так, Вы сохраните репутацию добросовестного
                        заемщика и сможете получить скидки при последующем обращении в организацию.
                        Предложение не является оффертой.
                    </p>
                    <p className="footer__text">
                        Сервис не является банком или кредитором, не относится к финансовым
                        учреждениям и не несёт ответственности за последствия любые заключенные
                        договоры кредитования или условия по ним.
                    </p>
                    <p className="footer__text">
                        Проект осуществляет только подбор кредитов и микрозаймов между лицом,
                        желающим взять займ, и кредитными учреждениями, которые на основании ФЗ «О
                        потребительском кредите (займе)» от 21.12.2013 № 353-ФЗ имеют право
                        осуществлять профессиональную деятельность по предоставлению потребительских
                        займов. ВАЖНО: предоплату берут только мошенники!
                    </p>
                    <p className="footer__text">
                        Сайт не является представительством МФО или банком, не осуществляет выдачу
                        займов и кредитов. Все рекомендуемые на сайте кредитные учреждения имеют
                        соответствующие лицензии. Условия неуплаты можно уточнить на сайте МФО или
                        банка.
                    </p>
                    <p className="footer__text">
                        Заявка направляется в банки-партнеры: АО “Тинькофф Банк”, ПАО “Совкомбанк”,
                        АО “ОТП Банк”, АО КБ “Ситибанк”, ПАО “СКБ-банк” и другие Лицензии
                        организаций-партнеров: №1481, №2673, №1326, №1460, №3251, №3354,
                        №2120177002022, №1127746537764, №2110552000304, №2120742002054,
                        №651303045004102, №2120754001243, №651303552003006, №651303045003951,
                        №1145476064711, №651303042002657, №651303045003161
                    </p>
                    <p className="footer__text " {...{ content: 'noindex' }}>
                        ООО «Кредитмарт» г. Москва, ул. Павловская д.6 ИНН 7725321452 / КПП
                        772501001. ОГРН 1167746596896. ИП Крымский П.В. ИНН 772577914404 ОГРНИП
                        322774600321768{' '}
                    </p>
                </div>
            </footer>
            <Modal
                isOpen={showModal}
                onRequestClose={() => {
                    setShowModal(false);
                }}
                style={modalStyles}
            >
                <div className="modal__container">
                    <button
                        className="modal__close-button"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        &#x2715;
                    </button>
                    <div
                        ref={(node) => {
                            if (node) {
                                node.innerHTML = TERMS;
                            }
                        }}
                    />
                </div>
            </Modal>
        </>
    );
};

export default Footer;
