export const TERMS = `<style>
#popup_agreement2 span.right-align {
    text-align: right!important;
    width: 100%!important;
}
#popup_agreement2 span.bold {
    font-weight: bold!important;
}
.paragraph, .paragraph__main {
    margin: 10px 0!important;
}
#popup_agreement2 table {
    border: 1px solid black!important;
    border-collapse: collapse!important;
}
#popup_agreement2 th {
    text-align: center!important;
}
#popup_agreement2 th, #popup_agreement2 td {
    width: 50%!important;
    vertical-align: top!important;
    padding: 5px!important;
}
</style>
<div id="popup_agreement2" class="popup_agreement" style="display: block;">
    <span class="right-align" style="display: block;">Утверждено <span class="bold">«РЕШБАНК»</span></span>
    <span class="right-align" style="display: block;">Генеральный директор ООО «КРЕДИТМАРТ»</span>
    <span class="right-align" style="display: block;">Баранцова М. Е.</span>
    <span class="right-align" content="noindex" style="display: block;">ИП Крымский П.В.</span>
    <span class="right-align" style="display: block;">от 15.01. 2023 г.</span>

    <span class="center"><span class="bold">Согласие на обработку персональных данных.</span></span>

    <div class="paragraph">
        Настоящим даю свое согласие Обществу с ограниченной ответственностью <span class="bold">ООО «КРЕДИТМАРТ» (ИНН/КПП 772501001/772501001) и <span content="noindex">ИП Крымский П.В. ОГРНИП 322774600321768 от 25.05.2022г.</span> </span>, (далее – Оператор), на сбор, запись, систематизацию, накопление, хранение, уточнение
        (обновление, изменение), извлечение, использование, обезличивание, блокирование, удаление и уничтожение, в том числе автоматизированные, своих персональных данных в специализированной электронной базе данных о моих фамилии, имени,
        отчестве, дате и месте рождения, адресе, семейн фом, социальном, имущественном положении, образовании, профессии, доходах, месте работы, а также иной информации личного характера, которая может быть использована при предоставлении
        Оператором услуг, а также для информирования меня об услугах, предложения мне услуг Оператора.
    </div>

    <div class="paragraph">
        Я согласен на предоставление мне информации и предложение услуг путем направления почтовой корреспонденции по моему домашнему адресу, посредством электронной почты, телефонных обращений, СМС – сообщений, сообщений в мессенджерах
        Ватсап (WhatsApp), Вайбер (Viber) и Телеграм (Telegram).
    </div>

    <div class="paragraph">
        Я соглашаюсь с тем, что Оператор под свою ответственность вправе поручить обработку персональных данных любому третьему лицу по своему усмотрению при условии соблюдения требований ст.6 ФЗ «О персональных данных», в том числе
        осуществления таким лицом конфиденциальности и защиты моих персональных данных. Передача моих персональных данных третьей стороне и получение от третьей стороны персональных данных может осуществляться указанными операторами
        персональных данных с даты подписания данного согласия.
    </div>

    <div class="paragraph">
        Я согласен в том числе на оценку моей кредитоспособности, расчёта и/или получения моего скорингового балла Кредитным бюро и/или от Кредитного бюро. На передачу и обработку персональных данных в ООО «ЭкспертБизнесКонсалтинг».
    </div>


    <div class="paragraph">
        Данное согласие действует с момента подписания настоящего заявления в течение срока предоставления Оператором услуг и пяти лет после прекращения указанных услуг. По истечении указанного срока действие настоящего заявления считается
        продленным на каждые следующие пять лет при отсутствии у Оператора сведений о его отзыве.
    </div>

    <div class="paragraph">
        Я также соглашаюсь с тем, что регистрация на <a class="popup_agreement--link" href="https://reshbank.online" target="_blank">сайте</a> и/или оформление заявки на услуги Оператора с учетом предварительного ознакомления с настоящим текстом
        является в соответствии с п.1 ст.9 ФЗ «О персональных данных» достаточной формой согласия на обработку моих персональных данных. Указанная форма согласия позволяет подтвердить факт получения моего согласия, при этом письменная форма
        или иные доказательства для дополнительного подтверждения моего свободного волеизъявления не потребуются.
    </div>

    <div class="paragraph">Данное согласие может быть отозвано путем направления на электронный̆ адрес Оператора заявления info@reshbank.online</div>


</div>`;
