type JSONResponse<T> = Promise<{
    data?: T;
    errors?: Array<{ message: string }>;
}>;

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
function parseJSON(response: Response) {
    return new Promise((resolve) =>
        response.json().then((json) =>
            resolve({
                status: response.status,
                ok: response.ok,
                json,
            }),
        ),
    );
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export function request<T = any>(url: string, options: RequestInit): JSONResponse<T> {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(parseJSON)
            .then((response: any) => {
                if (response.ok) {
                    return resolve(response.json);
                }

                return reject(response.json.meta.error);
            })
            .catch((error) =>
                reject({
                    networkError: error.message,
                }),
            );
    });
}

export const scrollIntoView = (name: string) => {
    if (name) {
        document
            .querySelector(`a[data-name=${name}`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
};

export const getBadgeContent = (points: number) => `+${points}%`;
