import { FC } from 'react';

import BannerThanks from './../layout/banner/bannerThanks/BannerThanks';
import Footer from './../layout/footer/Footer';
import Header from './../layout/header/Header';

const Thanks: FC = () => {
    return (
        <>
            <Header />
            <BannerThanks />
            <Footer />
        </>
    );
};

export default Thanks;
