import { FC, useContext, useRef, useState } from 'react';
import Modal from 'react-modal';

import { getBadgeContent } from '../../../utils';
import { CitySuggest, EmailSuggest, FioSuggest } from '../../elements/field';
import { SUBSCRIBE } from '../../layout/policy/Subscribe';
import { TERMS } from '../../layout/policy/Terms';

import { setCheckbox1, setSum, setDateBirth, setPhone } from './../../../actions/formQuest';
import { FormQuestContext } from './../../../reducer/reducer';
import useStep1 from './../../../services/formQuestServise/Step1Servise';
import { Anchor } from './../../elements/anchor/Anchor';
import ButtonForm from './../../elements/button/buttonForm/ButtonForm';
import Input from './../../elements/input/Input';
import './style.css';
import { formStepTypes } from './../types';

type TPolicy = typeof TERMS | typeof SUBSCRIBE;

const modalStyles = { content: { zIndex: 11 }, overlay: { zIndex: 11 } };

const setNextFocus = (fields: any[]) => () => {
    fields.some(({ ref, el }) => {
        if (el.isValid === true) {
            return false;
        }

        if (ref.current) {
            ref.current.focus();
        }

        return true;
    });
};

const FormStep1: FC<formStepTypes> = ({ setStep }) => {
    const sumRef = useRef<HTMLInputElement>();
    const cityRef = useRef<HTMLInputElement>();
    const phoneRef = useRef<HTMLInputElement>();
    const emailRef = useRef<HTMLInputElement>();

    const [modal, setModal] = useState<TPolicy | string>('');

    const { state, dispatch } = useContext(FormQuestContext);
    const { checkbox1, sum, dateBirth, phone, city, email } = state;

    const {
        maskSum,
        validationSum,
        maskDateBirth,
        validationDateBirth,
        maskPhone,
        validationPhone,
    } = useStep1();

    const onSetSum = (value: string) => {
        setSum(dispatch, sum, maskSum(value), false);
    };
    const onRemoveFocusSum = (value: string) => {
        const isValid = validationSum(sum.errorMessages, value);
        setSum(dispatch, sum, maskSum(value), isValid);
    };

    const onSetDateBirth = (value: string) => {
        setDateBirth(dispatch, dateBirth, maskDateBirth(value), false);

        if (validationDateBirth(dateBirth.errorMessages, value) === true) {
            setNextFocus([
                { ref: phoneRef, el: phone },
                { ref: emailRef, el: email },
            ])();
        }
    };
    const onRemoveFocusDateBirth = (value: string) => {
        const isValid = validationDateBirth(dateBirth.errorMessages, value);
        setDateBirth(dispatch, dateBirth, maskDateBirth(value), isValid);
    };

    const onSetPhone = (value: string) => {
        setPhone(dispatch, phone, maskPhone(value), false);

        if (validationPhone(dateBirth.errorMessages, value) === true) {
            setNextFocus([{ ref: emailRef, el: email }])();
        }
    };

    const onRemoveFocusPhone = (value: string) => {
        const isValid = validationPhone(phone.errorMessages, value);
        setPhone(dispatch, phone, maskPhone(value), isValid);
    };

    const handleClick = (policy: any) => (e: any) => {
        e.preventDefault();
        setModal(policy);
    };

    const handleRequestClose: ReactModal.Props['onRequestClose'] = () => {
        setModal('');
    };

    return (
        <div className="form-step-1 form-step">
            <Anchor name="fio">
                <FioSuggest
                    onSetValidValue={setNextFocus([
                        { ref: sumRef, el: sum },
                        { ref: cityRef, el: city },
                        { ref: phoneRef, el: phone },
                        { ref: emailRef, el: email },
                    ])}
                />
            </Anchor>
            <Anchor name="sum">
                <Input
                    title="Сумма"
                    placeholder="800 000"
                    value={sum.value}
                    setValue={onSetSum}
                    removeFocus={onRemoveFocusSum}
                    isValid={sum.isValid}
                    badgeContent={getBadgeContent(sum.points)}
                    inputRef={sumRef}
                />
            </Anchor>
            <Anchor name="dateBirth">
                <Input
                    title="Дата рождения"
                    placeholder="дд.мм.гггг"
                    value={dateBirth.value}
                    setValue={onSetDateBirth}
                    removeFocus={onRemoveFocusDateBirth}
                    isValid={dateBirth.isValid}
                    badgeContent={getBadgeContent(dateBirth.points)}
                />
            </Anchor>
            <Anchor name="phone">
                <Input
                    title="Телефон"
                    placeholder="+7 (___) ___-__-__"
                    value={phone.value}
                    setValue={onSetPhone}
                    removeFocus={onRemoveFocusPhone}
                    isValid={phone.isValid}
                    badgeContent={getBadgeContent(phone.points)}
                    inputRef={phoneRef}
                />
            </Anchor>
            <Anchor name="email">
                <EmailSuggest nextFieldRef={cityRef} inputRef={emailRef} />
            </Anchor>
            <Anchor name="city">
                <CitySuggest inputRef={cityRef} />
            </Anchor>
            <div className="checkbox">
                <div className="checkbox__error">
                    {!checkbox1.value
                        ? 'Примите согласие для отправки заявки и получения кредита'
                        : ''}
                </div>
                <div className="checkbox__wrap">
                    <div className="checkbox__control">
                        <span className="checkbox__label">
                            <div
                                className={`checkbox__input ${checkbox1.isValid ? 'active' : ''}`}
                                onClick={() =>
                                    setCheckbox1(
                                        dispatch,
                                        checkbox1,
                                        checkbox1.isValid ? '' : 'true',
                                        checkbox1.isValid ? false : true,
                                    )
                                }
                            />
                            <span className="checkbox__text">
                                Заполняя заявку на сайте вы даете согласие со следующими условиями:{' '}
                                <a
                                    className="checkbox__text checkbox__link-text"
                                    onClick={handleClick(SUBSCRIBE)}
                                >
                                    {' '}
                                    Политика конфиденциальности{' '}
                                </a>{' '}
                                и{' '}
                                <a
                                    className="checkbox__text checkbox__link-text"
                                    onClick={handleClick(TERMS)}
                                >
                                    Согласие на обработку персональных данных
                                </a>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="form-step__btns">
                <ButtonForm text="Далее" setFunction={setStep} />
            </div>
            <Modal isOpen={!!modal} onRequestClose={handleRequestClose} style={modalStyles}>
                <div className="modal__container">
                    <button className="modal__close-button" onClick={handleRequestClose}>
                        &#x2715;
                    </button>
                    <div
                        ref={(node) => {
                            if (node) {
                                node.innerHTML = modal;
                            }
                        }}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default FormStep1;
