import { FC, useContext, useEffect, useRef, useState } from 'react';
import { RenderSuggestion } from 'react-autosuggest';
import HighlightWords from 'react-highlight-words';

import {
    setContactPersonFio,
    setContactPersonFirstname,
    setContactPersonSurname,
    setContactPersonPatronymic,
} from '../../../../actions/formQuest';
import { useDadataNames } from '../../../../hooks/dadataApiHooks';
import { FormQuestContext } from '../../../../reducer/reducer';
import useStep1 from '../../../../services/formQuestServise/Step1Servise';
import { SuggestInput } from '../../suggestInput/SuggestInput';

/** параметры дадаты */
const locations = [{ country_iso_code: 'RU' }];
const params = { count: 10, locations };

/** маппер для получения suggestionValue */
const getSuggestionValue = (suggestion: any) => suggestion.value;

/** рендер подсказки в списке */
const renderSuggestion: RenderSuggestion<any> = (suggestion, params) => {
    return (
        <div>
            <HighlightWords searchWords={[params.query]} textToHighlight={suggestion.value} />
        </div>
    );
};

export const ContactPersonFioSuggest: FC = () => {
    const [focused, setFocused] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const { data, trigger } = useDadataNames(params);

    const { state, dispatch } = useContext(FormQuestContext);
    const {
        contactPersonFio,
        contactPersonFirstname,
        contactPersonSurname,
        contactPersonPatronymic,
    } = state;

    const { validationFio, maskFio } = useStep1();

    const handleChange = (e: any, { newValue }: any) => {
        setContactPersonFio(dispatch, contactPersonFio, newValue, false);
        setContactPersonFirstname(dispatch, contactPersonFirstname, '', true);
        setContactPersonSurname(dispatch, contactPersonSurname, '', true);
        setContactPersonPatronymic(dispatch, contactPersonPatronymic, '', true);
    };

    const handleSuggestionsFetchRequested = ({ value }: any) => {
        trigger(value);
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const handleSuggestionSelected = (e: any, data: any) => {
        const fioValue = data.suggestion?.value || '';
        const isValid = validationFio(contactPersonFio.errorMessages, fioValue);

        setContactPersonFio(
            dispatch,
            contactPersonFio,
            isValid !== true ? `${maskFio(fioValue)} ` : maskFio(fioValue),
            isValid,
        );
        setContactPersonFirstname(
            dispatch,
            contactPersonFirstname,
            data.suggestion?.data?.name || '',
            true,
        );
        setContactPersonSurname(
            dispatch,
            contactPersonSurname,
            data.suggestion?.data?.surname || '',
            true,
        );
        setContactPersonPatronymic(
            dispatch,
            contactPersonPatronymic,
            data.suggestion?.data?.patronymic || '',
            true,
        );
    };

    useEffect(() => {
        if (data) {
            setSuggestions(data.suggestions);
        }
    }, [data]);

    const inputProps = {
        placeholder: 'Иванов Иван Иванович',
        value: contactPersonFio.value,
        onChange: handleChange,
        onKeyDown: (e: any) => {
            if (e.key === 'Enter') {
                const isValid = validationFio(
                    contactPersonFio.errorMessages,
                    contactPersonFio.value,
                );
                setContactPersonFio(
                    dispatch,
                    contactPersonFio,
                    maskFio(contactPersonFio.value),
                    isValid,
                );

                const fileds = (contactPersonFio.value || '').split(' ');
                setContactPersonFirstname(dispatch, contactPersonFirstname, fileds[1] || '', true);
                setContactPersonSurname(dispatch, contactPersonSurname, fileds[0] || '', true);
                setContactPersonPatronymic(
                    dispatch,
                    contactPersonPatronymic,
                    fileds[2] || '',
                    true,
                );
            }
        },
        onFocus: () => {
            setFocused(true);

            if (!focused && inputRef.current) {
                const r = inputRef.current;
                setTimeout(() => {
                    r.setSelectionRange(
                        contactPersonFio.value.length,
                        contactPersonFio.value.length,
                    );
                }, 10);
            }
        },
        onBlur: () => {
            setFocused(false);

            const isValid = validationFio(contactPersonFio.errorMessages, contactPersonFio.value);
            setContactPersonFio(
                dispatch,
                contactPersonFio,
                maskFio(contactPersonFio.value),
                isValid,
            );
        },
        ref: inputRef,
    };

    return (
        <SuggestInput
            label="ФИО владельца номера"
            inputProps={inputProps}
            isValid={contactPersonFio.isValid}
            suggestions={suggestions}
            renderSuggestion={renderSuggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            onSuggestionSelected={handleSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
        />
    );
};
